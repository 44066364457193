import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import axios from 'axios';

export default createStore({
  state: {
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    user: null,
    userData: null,
    authIsReady: false,
    globalLoader: {
      show: false,
      head: "Loading..",
      subheader: "Don't close this tab."
    }
  },
  mutations: {
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    // Set user to Vuex store
    setUser(state, userData)
    {
      state.user = userData;
    },
    updateInternetConnectionStatus(state, status)
    {
      state.internetConnection = status;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Ładowanie..",
        subheader: "Nie zamykaj tego okna."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },

  },
  actions: {
    //login new user
    async login(context,payload)
    {
      const signIn = await auth.signInWithEmailAndPassword(payload.email, payload.password);
      if (signIn) {
        const userCredentials = await db.collection("UsersData").doc(signIn.user.uid).get();
        if(userCredentials.exists){
          context.commit('setGlobalLoader',{
            show: true,
            head: "Sprawdzanie twoich danych..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        context.commit('setUserCredentials', userCredentials.data());
        context.commit('setUser', signIn.user);
        }else{
          throw new Error("Could not complete login action.")
        }
          
      } else {
        throw new Error("Could not complete login action.")
      }
    },
    // logout user;
    async logout(context)
    {
      context.state.authIsReady = false;
      await auth.signOut();
      context.commit('setUser', null);
      context.commit("resetGlobalLoader");
    }
  },
  modules: {
  }
})
