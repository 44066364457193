require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyDKTZARZZseNhG50DtySUK2mWJwyNp4Gsc",
  authDomain: "wbtank-65cca.firebaseapp.com",
  projectId: "wbtank-65cca",
  storageBucket: "wbtank-65cca.appspot.com",
  messagingSenderId: "758269403718",
  appId: "1:758269403718:web:93133fc6184fc44b9ee047",
  measurementId: "G-04KXQN3SCW"
};





  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };